.a-container {
  position: absolute;
  top: 10%;
  left: 20%;

  width: 350px;
  padding: 40px;
  background: #25354d;
  box-sizing: border-box;
  box-shadow: 0 15px 25px (rgba(0, 0, 0, 0.5));
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
}
.a-container h1 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
  border-bottom: 6px solid #edf0f5;
  margin-bottom: 20px;
  padding: 13px 0;
  letter-spacing: 2px;
  text-shadow: 1px 1px #d5d5d8;
}
.btn-activity {
  width: 100%;
  background: none;
  border: 2px solid #d5d5d8;
  color: white;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 2px;
}
.a-container label {
  margin: 5px;
  padding: 0;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}
.table-container {
  margin: 0 auto;
  margin-top: 20px;
  padding: 5px;
}
.table-container h1 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
  border-bottom: 6px solid #edf0f5;
  margin-bottom: 20px;
  padding: 13px 0;
  letter-spacing: 3px;
  text-shadow: 2px 1px #d5d5d8;
}
.table-container a {
  color: #4c65ff;
  font-size: 16px;
}
.table-activity th {
  color: #2f2f3c;
  font-size: 20px;
  font-weight: bold;
}
.table-body {
  color: #2f2f3c;
  font-size: 16px;
  border-bottom: 1px solid #edf0f5;
}
