.box {
  position: absolute;
  top: 10%;
  right: 20%;

  width: 350px;
  padding: 40px;
  background: #25354d;
  box-sizing: border-box;
  box-shadow: 0 15px 25px (rgba(0, 0, 0, 0.5));
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}
.box h1 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
  border-bottom: 6px solid #4c65ff;
  margin-bottom: 20px;
  padding: 13px 0;
  letter-spacing: 1px;
}

.inputBox {
  display: flex;

  width: 100%;
  overflow: hidden;
  padding: 10px 0;
  margin: 8px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid #4c65ff;
  outline: none;
  background: transparent;
}
.inputBox i {
  width: 26px;
  float: left;
  text-align: center;
}
.inputBox input {
  border: none;
  outline: none;
  background: none;
  color: white;
  font-size: 18px;
  width: 80%;
  float: left;
  margin: 0 10px;
}

.box p {
  color: white;
  font-size: 16px;
  margin: 5px;
}
.box a {
  color: #4c65ff;
  font-size: 18px;
  margin: 5px;
}
.box .inputBox input:focus ~ label {
  top: -18px;
  left: 0;
  color: #03a9f4;
  font-size: 12px;
}

.box input[type="submit"] {
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  background: #03a9f4;
  padding: 10px 20px;
}
.box .inputBox i {
  float: left;
}

.btn-login {
  width: 100%;
  background: none;
  border: 2px solid #4c65ff;
  color: white;
  padding: 5px;
  cursor: pointer;
  font-size: 16px;
}
