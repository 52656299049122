.navbar {
  text-align: center;
  width: 100%;
  background-color: #f4f5f7;
  text-decoration: none;
}

.navbar a .home-link {
  color: #f4f5f7;
  font-family: "Noto Sans", sans-serif;
  font-size: 2.25rem;
  font-weight: bold;
  text-decoration: none;
}
